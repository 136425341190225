import { HOAC } from 'store/utils'

const REDUCER = 'SEARCH_'

export const actionTypes = {
  SET_VALUE: `${REDUCER}SET_VALUE`
}

export const actions = {
  setValue: HOAC<string>(actionTypes.SET_VALUE)
}
