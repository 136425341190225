/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Checkbox,
  FormControl,
  LinearProgress,
  MenuItem,
  Select
} from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import cns from 'classnames'
import { Button, Typography } from 'vg-components'
import { ICompany } from 'types/company'
import './Company.sass'
import EditableTextField from 'components/EditableTextField/EditableTextField'
import EditableNumberField from 'components/EditableNumberField/EditableNumberField'
import { languageOptions, landOptions, statusOptions } from './language-options'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)


interface IProps {
  company: ICompany
  onEdit: (id: string, company: Partial<ICompany>) => void
  isBeingEdited: boolean
  className?: string
}

export const Company: React.FC<IProps> = ({
  company,
  onEdit,
  isBeingEdited,
  className
}) => {


  const defaultBoolState = {
    sandboxDataset: company.sandboxDataset,
    competencePartner: company.competencePartner,
    suppNaturalGas: company.suppNaturalGas,
    suppLiquidGas: company.suppLiquidGas,
    suppOil: company.suppOil,
    suppEnvironmentalHeat: company.suppEnvironmentalHeat,
    suppSolar: company.suppSolar,
    visibleInConfigurator: company.visibleInConfigurator,
    suppFinancing: company.suppFinancing,
    suppPhotovoltaic: company.suppPhotovoltaic,
    lightEnabled: company.lightEnabled,
    classicEnabled: company.classicEnabled
  }

  const defaultInputsState = {
    marketingName: { value: company.marketingName || '', isEdit: false },
    bccEmail: { value: company.bccEmail, isEdit: false },
    code: { value: company.code, isEdit: false },
    preferredLanguage: { value: company.preferredLanguage, isEdit: false },
    status: { value: company.status, isEdit: false },
    countryCode: { value: company.countryCode, isEdit: false },
    rating: { value: company.rating, isEdit: false },

    email: { value: company.email, isEdit: false },
    website: { value: company.website, isEdit: false },
    phone: { value: company.phone, isEdit: false },
    city: { value: company.city, isEdit: false },
    postalCode: { value: company.postalCode, isEdit: false },
    streetHouseNo: { value: company.streetHouseNo, isEdit: false },
    companyName: { value: company.companyName, isEdit: false },
  }

  const defaultAreasState: {
    id: number, isEdit: boolean, postalCode: string
  }[] = (company.areas || []).map((a, idx) => ({
    id: idx, isEdit: false, postalCode: a.postalCode
  }))

  const [boolState, setBoolState] = useState({ ...defaultBoolState })
  const [inputs, setInputsState] = useState({ ...defaultInputsState })
  const [areasState, setAreasState] = useState(defaultAreasState)
  const [currentAreasHighestId, setCurrentAreasHighestId] = useState(defaultAreasState.length + 1)
  const [isPristine, setIsPristine] = useState(true)



  const setDafaultStates = () => {
    setIsPristine(true)
    setBoolState(defaultBoolState)
    setInputsState(defaultInputsState)
  }

  useEffect(() => {
    if (isBeingEdited === false) // company was just edited
      setDafaultStates()
  }, [isBeingEdited])


  const handleBoolChange = (event: ChangeEvent<HTMLInputElement>) => {

    setIsPristine(false)

    //if (event.target.name !== "classicEnabled" && event.target.name !== "lightEnabled") {
      setBoolState({ ...boolState, [event.target.name]: event.target.checked })
    //}





/*    if (event.target.name === "classicEnabled") {

      boolState.classicEnabled = event.target.checked
      setBoolState({ ...boolState, ["lightEnabled"]: false })
    }
    if (event.target.name === "lightEnabled") {


      boolState.lightEnabled = event.target.checked
      setBoolState({ ...boolState, ["classicEnabled"]: false })
    }*/


  }

  const handleStringsChange = (key: keyof typeof inputs, value: string) => {
    setIsPristine(false)
    setInputsState({
      ...inputs,
      [key]: {
        ...inputs[key],
        value
      }
    })
  }

  const toggleStringsIsEdit = (key: keyof typeof inputs) => {
    setInputsState({
      ...inputs,
      [key]: {
        isEdit: !inputs[key].isEdit,
        value: inputs[key].value
      }
    })
  }

  const toggleAreasIsEdit = (id: number) => setAreasState(
    areasState.map(i => {
      if (i.id !== id) return i

      return {
        ...i,
        isEdit: !i.isEdit
      }
    })
  )

  const handleAreasValueChange = (id: number, value: string) => {
    setIsPristine(false)
    setAreasState(
      areasState.map(i => {
        if (i.id !== id) return i

        return {
          ...i,
          postalCode: value
        }
      })
    )
  }

  const handleAreasAddItem = () => {
    setIsPristine(false)
    setAreasState([
      ...areasState,
      {
        id: currentAreasHighestId,
        postalCode: '',
        isEdit: false
      }
    ])
    setCurrentAreasHighestId(currentAreasHighestId + 1)
  }

  const handleAreasRemoveItem = (id: number) => {
    setIsPristine(false)
    setAreasState(areasState.filter(a => a.id !== id))
  }

  const check_error = (): boolean => {
    if (boolState.lightEnabled == true || boolState.classicEnabled == true) {
      const required_field = [];
      if (inputs.email.value == null || inputs.email.value.length < 5 && !inputs.email.value.includes('@')) {
        required_field.push("email")

      }

      if (inputs.phone.value == null || inputs.phone.value.length < 5) {
        required_field.push("phone")

      }

      if (inputs.city.value == null || inputs.city.value.length < 2) {
        required_field.push("city")

      }

      if (inputs.companyName.value == null || inputs.companyName.value.length < 2) {
        required_field.push("company Name")

      }

      if (inputs.website.value == null || inputs.website.value.length < 2) {
        required_field.push("website")

      }


      if (inputs.postalCode.value == null || inputs.postalCode.value.length < 2) {
        required_field.push("postal Code")

      }

      if (required_field.length > 0) {
        let text = "";
        for (let i = 0; i < required_field.length; i++) {
          text += "Please fill the " + required_field[i] + ".<br>";
        }

        let title = "Classic"

        if (boolState.lightEnabled == true && boolState.classicEnabled == false) {
          title = "Light"
        }
        else if (boolState.lightEnabled == true && boolState.classicEnabled == true) {
          title = "Classic and Light"
        }

        MySwal.fire({
          icon: 'error',
          title: title + ' could not be enabled due to missing field(s).',
          html: text,
          footer: '<a>Why do I have this issue?</a>'
        })
        return false;
      }
      return true;

    }
    return true
  }
  const handleEdit = () => {

    if (check_error() == false) {
      console.log("has error")
      return
    }



    onEdit(company.id, {
      ...company,
      areas: areasState.map(a => ({ postalCode: a.postalCode })),
      ...boolState,
      ...Object.keys(inputs).reduce((acc, curKey) => {
        // @ts-ignore
        acc[curKey] = inputs[curKey as keyof typeof inputs].value

        return acc
      }, {})
    })

  }

  return (
    <div className={cns('company-box', className)}>
      <FormControl className="company-form" disabled={isBeingEdited}>
        {isBeingEdited && (
          <LinearProgress classes={{ root: 'company-edit-loader' }} />
        )}
        <table className="company">
          <tbody>
            <tr>
              <td>Field:</td>
              <td>Value:</td>
            </tr>
            <tr>
              <td>Marketing Name:</td>
              <td>
                <EditableTextField
                  value={inputs.marketingName.value}
                  onChange={e => handleStringsChange('marketingName', e.target.value)}
                  disabled={!inputs.marketingName.isEdit || isBeingEdited}
                  toggleDisabled={() => toggleStringsIsEdit('marketingName')}
                  isToggleDisabled={isBeingEdited}
                />
              </td>
            </tr>
            <tr>
              <td>Sandbox Data Set:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.sandboxDataset}
                  onChange={handleBoolChange}
                  name="sandboxDataset"
                />
              </td>
            </tr>
            <tr>
              <td>Competence partner:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.competencePartner}
                  onChange={handleBoolChange}
                  name="competencePartner"
                />
              </td>
            </tr>
            <tr>
              <td>Supports CNG:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.suppNaturalGas}
                  onChange={handleBoolChange}
                  name="suppNaturalGas"
                />
              </td>
            </tr>
            <tr>
              <td>Supports LPG:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.suppLiquidGas}
                  onChange={handleBoolChange}
                  name="suppLiquidGas"
                />
              </td>
            </tr>
            <tr>
              <td>Supports Oil:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.suppOil}
                  onChange={handleBoolChange}
                  name="suppOil"
                />
              </td>
            </tr>
            <tr>
              <td>Supports Heat Pump:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.suppEnvironmentalHeat}
                  onChange={handleBoolChange}
                  name="suppEnvironmentalHeat"
                />
              </td>
            </tr>
            <tr>
              <td>Supports Solar:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.suppSolar}
                  onChange={handleBoolChange}
                  name="suppSolar"
                />
              </td>
            </tr>
            <tr>
              <td>Visible in Configurator:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.visibleInConfigurator}
                  onChange={handleBoolChange}
                  name="visibleInConfigurator"
                />
              </td>
            </tr>
            <tr>
              <td>Financing:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.suppFinancing}
                  onChange={handleBoolChange}
                  name="suppFinancing"
                />
              </td>
            </tr>
            <tr>
              <td>Photovoltaics:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.suppPhotovoltaic}
                  onChange={handleBoolChange}
                  name="suppPhotovoltaic"
                />
              </td>
            </tr>
            <tr>
              <td>Classic Partner:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.classicEnabled}
                  onChange={handleBoolChange}
                  name="classicEnabled"
                />
              </td>
            </tr>
            <tr>
              <td>Light Partner:</td>
              <td>
                <Checkbox
                  color="primary"
                  checked={boolState.lightEnabled}
                  onChange={handleBoolChange}
                  name="lightEnabled"
                />
              </td>
            </tr>




            <tr>
              <td>Company Name:</td>
              <td>
                <EditableTextField
                  value={inputs.companyName.value}
                  onChange={e => handleStringsChange('companyName', e.target.value)}
                  disabled={true}
                  toggleDisabled={() => toggleStringsIsEdit('companyName')}
                  isToggleDisabled={true}
                />
              </td>
            </tr>





            <tr>
              <td>Email:</td>
              <td>
                <EditableTextField
                  value={inputs.email.value}
                  onChange={e => handleStringsChange('email', e.target.value)}
                  disabled={!inputs.email.isEdit || isBeingEdited}
                  toggleDisabled={() => toggleStringsIsEdit('email')}
                  isToggleDisabled={isBeingEdited}
                />
              </td>
            </tr>



            <tr>
              <td>Phone:</td>
              <td>
                <EditableTextField
                  value={inputs.phone.value}
                  onChange={e => handleStringsChange('phone', e.target.value)}
                  disabled={true}
                  toggleDisabled={() => toggleStringsIsEdit('phone')}
                  isToggleDisabled={true}
                />
              </td>
            </tr>



            <tr>
              <td>Website:</td>
              <td>
                <EditableTextField
                  value={inputs.website.value}
                  onChange={e => handleStringsChange('website', e.target.value)}
                  disabled={true}
                  toggleDisabled={() => toggleStringsIsEdit('website')}
                  isToggleDisabled={true}
                />
              </td>
            </tr>





            <tr>
              <td>City:</td>
              <td>
                <EditableTextField
                  value={inputs.city.value}
                  onChange={e => handleStringsChange('city', e.target.value)}
                  disabled={true}
                  toggleDisabled={() => toggleStringsIsEdit('city')}
                  isToggleDisabled={true}
                />
              </td>
            </tr>


            <tr>
              <td>Street and House Number:</td>
              <td>
                <EditableTextField
                  value={inputs.streetHouseNo.value}
                  onChange={e => handleStringsChange('streetHouseNo', e.target.value)}
                  disabled={true}
                  toggleDisabled={() => toggleStringsIsEdit('streetHouseNo')}
                  isToggleDisabled={true}
                />
              </td>
            </tr>


            <tr>
              <td>Postal Code:</td>
              <td>
                <EditableTextField
                  value={inputs.postalCode.value}
                  onChange={e => handleStringsChange('postalCode', e.target.value)}
                  disabled={true}
                  toggleDisabled={() => toggleStringsIsEdit('postalCode')}
                  isToggleDisabled={true}
                />
              </td>
            </tr>





            <tr>
              <td>Bcc Mail:</td>
              <td>
                <EditableTextField
                  value={inputs.bccEmail.value}
                  onChange={e => handleStringsChange('bccEmail', e.target.value)}
                  disabled={!inputs.bccEmail.isEdit || isBeingEdited}
                  toggleDisabled={() => toggleStringsIsEdit('bccEmail')}
                  isToggleDisabled={isBeingEdited}
                />
              </td>
            </tr>
            <tr>
              <td>Rating:</td>
              <td>
                <EditableNumberField
                  value={inputs.rating.value}
                  onChange={e => handleStringsChange('rating', e.target.value)}
                  disabled={!inputs.rating.isEdit || isBeingEdited}
                  isToggleDisabled={isBeingEdited}
                  toggleDisabled={() => toggleStringsIsEdit('rating')}
                />
              </td>
            </tr>
            <tr>
              <td>Code:</td>
              <td>
                <EditableTextField
                  value={inputs.code.value}
                  onChange={e => handleStringsChange('code', e.target.value)}
                  disabled={ !inputs.code.isEdit || isBeingEdited}
                  toggleDisabled={() => toggleStringsIsEdit('code')}
                  isToggleDisabled={isBeingEdited}
                />
              </td>
            </tr>
            <tr>
              <td>Country:</td>
              <td>
                <Select
                  
                  value={inputs.countryCode.value}
                  defaultValue={inputs.countryCode.value}
                  className="company-select"
                  MenuProps={{ classes: { paper: 'company-select-menu' } }}
                  onChange={e =>
                    handleStringsChange(
                      'countryCode',
                      e.target.value as string
                    )
                  }
                  disabled={true}
                >
                  {landOptions.map(value => (
                    <MenuItem
                      key={value}
                      value={value}
                      classes={{ root: 'company-select-menu-item' }}
                    >
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <td>Preferred language (z.B. DE):</td>
              <td>
                <Select
                  value={inputs.preferredLanguage.value}
                  defaultValue={inputs.preferredLanguage.value}
                  className="company-select"
                  MenuProps={{ classes: { paper: 'company-select-menu' } }}
                  onChange={e =>
                    handleStringsChange(
                      'preferredLanguage',
                      e.target.value as string
                    )
                  }
                  disabled={isBeingEdited}
                >
                  {languageOptions.map(item => (
                    <MenuItem
                      disabled={!item.active}
                      key={item.value + item.label}
                      value={item.value}
                      classes={{ root: 'company-select-menu-item' }}
                    >
                      {item.value}
                    </MenuItem>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>
                <Select
                  value={inputs.status.value}
                  defaultValue={inputs.status.value}
                  className="company-select"
                  MenuProps={{ classes: { paper: 'company-select-menu' } }}
                  onChange={e =>
                    handleStringsChange(
                      'status',
                      e.target.value as string
                    )
                  }
                  disabled={isBeingEdited}
                >
                  {statusOptions.map(item => (
                    <MenuItem
                      key={item.value}
                      value={item.value}
                      classes={{ root: 'company-select-menu-item' }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </td>
            </tr>
            <tr>
              <td>Postal code area:</td>
              <td>
                <ul className="area-list">
                  {areasState.map((area, idx) => (
                    <li className="area-item" key={idx}>
                      <EditableTextField
                        value={area.postalCode}
                        onChange={e =>
                          handleAreasValueChange(area.id, e.target.value)
                        }
                        disabled={!area.isEdit}
                        toggleDisabled={() => toggleAreasIsEdit(area.id)}
                        isToggleDisabled={isBeingEdited}
                      />
                      <Button
                        disabled={isBeingEdited}
                        onClick={() => handleAreasRemoveItem(area.id)}
                      >
                        <RemoveIcon />
                      </Button>
                    </li>
                  ))}
                  <li className="area-item">
                    <Button
                      className="area-btn-add"
                      onClick={handleAreasAddItem}
                      disabled={isBeingEdited}
                    >
                      <AddIcon />
                    </Button>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </FormControl>
      <div className="company-btn-list">
        <Button
          className="company-btn"
          color="secondary"
          variant="outlined"
          disabled={isPristine || isBeingEdited}
          onClick={setDafaultStates}
        >
          <Typography className="area-item-text">Reset</Typography>
        </Button>
        <Button
          className="company-btn"
          color="primary"
          variant="outlined"
          disabled={isPristine || isBeingEdited}
          onClick={handleEdit}
        >
          <Typography>Save</Typography>
        </Button>
      </div>
    </div>
  )
}

export default Company
