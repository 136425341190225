/* eslint-disable no-unused-vars */
import { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { debounce } from 'lodash'
import { TextField } from 'vg-components'
import './Search.sass'
import { useSelector } from 'store'
import { actions } from 'store/search/actions'

interface IProps {
  onSearch: (companyName: string) => void
  defaultValue?: string
}

const Search: React.FC<IProps> = ({ onSearch, defaultValue }) => {
  const searchValue = useSelector(store => store.search.value)
  const dispatch = useDispatch()

  useEffect(() => {
    if (defaultValue) {
      dispatch(actions.setValue(defaultValue))
    }
  }, [])

  const debouncedHandleSearchCompany = useCallback(debounce((companyName: string) => {
    onSearch(companyName)
  }, 300), [])

  const isFirstRun = useRef(true)
  useEffect(() => {
    // omitting very first call
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    debouncedHandleSearchCompany(searchValue)
  }, [searchValue])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setValue(e.target.value))
  }

  return (
    <TextField
      value={searchValue}
      onChange={onChange}
      placeholder="Find a company..."
      InputProps={{
        className: 'search-input',
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  )
}

export default Search
