import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConfigurationProvider, Typography } from 'vg-components'
import 'theme/index.sass'
import App from 'containers/App/App'
import store from 'store'
import keycloak from './auth/keycloak'
import { ReactKeycloakProvider } from '@react-keycloak/web'

// @ts-ignore
const MyConfigurationProvider = ConfigurationProvider as any

ReactDOM.render(
  <React.StrictMode>
    <MyConfigurationProvider
      themeName="green"
      trackEvent={(f: any) => f}
      translate={(f: any) => f}
    >
      <ReactKeycloakProvider authClient={keycloak}
        initOptions={{
          onLoad: 'login-required'
        }}
        LoadingComponent={
          <Typography variant="h1" align="center">Authenticating...</Typography>
        }
        onEvent={(event, error) => {
          console.log('onKeycloakEvent', event, error)
        }}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </ReactKeycloakProvider>
    </MyConfigurationProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
