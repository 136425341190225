import { ICompany } from 'types'
import { HOAC } from 'store/utils'

const REDUCER = 'COMPANIES_'

export const actionTypes = {
  LOAD_COMPANY: `${REDUCER}LOAD_COMPANY`,
  LOAD_COMPANY_SUCCESS: `${REDUCER}LOAD_COMPANY_SUCCESS`,
  LOAD_COMPANY_ERROR: `${REDUCER}LOAD_COMPANY_ERROR`,
  LOAD_COMPANIES: `${REDUCER}LOAD_COMPANIES`,
  LOAD_COMPANIES_SUCCESS: `${REDUCER}LOAD_COMPANIES_SUCCESS`,
  LOAD_COMPANIES_ERROR: `${REDUCER}LOAD_COMPANIES_ERROR`,
  EDIT_COMPANY: `${REDUCER}EDIT_COMPANY`,
  EDIT_COMPANY_SUCCESS: `${REDUCER}EDIT_COMPANY_SUCCESS`,
  EDIT_COMPANY_ERROR: `${REDUCER}EDIT_COMPANY_ERROR`
}

export const actions = {
  loadCompany: HOAC<string>(actionTypes.LOAD_COMPANY),
  loadCompanySuccess: HOAC<ICompany>(actionTypes.LOAD_COMPANY_SUCCESS),
  loadCompanyError: HOAC<Error>(actionTypes.LOAD_COMPANY_ERROR),
  loadCompanies: HOAC(actionTypes.LOAD_COMPANIES),
  loadCompaniesSuccess: HOAC<ICompany[]>(actionTypes.LOAD_COMPANIES_SUCCESS),
  loadCompaniesError: HOAC<Error>(actionTypes.LOAD_COMPANIES_ERROR),
  editCompany: HOAC<string>(actionTypes.EDIT_COMPANY),
  editCompanySuccess: HOAC<ICompany>(actionTypes.EDIT_COMPANY_SUCCESS),
  editCompanyError: HOAC<[string, Error]>(actionTypes.EDIT_COMPANY_ERROR)
}
