import keycloak from 'auth/keycloak'
import axios from 'axios'

const axiosApiInstance = axios.create()

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async config => {
    // update token if expires in 5 sec
    await keycloak.updateToken(5)

    config.headers = {
      'Authorization': `Bearer ${keycloak.token}`
    }
    return config
  },
  error => {
    Promise.reject(error)
  })

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(response => {
  return response
}, async function(error) {
  // todo implement retry
  console.log('API request failed with error: ' + error)
  return Promise.reject(error)
})

export default axiosApiInstance
