import { IPageAble } from 'types/pageable'
import { HOAC } from 'store/utils'

const REDUCER = 'APP_'

export const actionTypes = {
  LOAD_COMPANIES: `${REDUCER}LOAD_COMPANIES`,
  LOAD_COMPANIES_SUCCESS: `${REDUCER}LOAD_COMPANIES_SUCCESS`,
  LOAD_COMPANIES_ERROR: `${REDUCER}LOAD_COMPANIES_ERROR`
}

export const actions = {
  loadCompanies: HOAC(actionTypes.LOAD_COMPANIES),
  loadCompaniesSuccess: HOAC<IPageAble>(
    actionTypes.LOAD_COMPANIES_SUCCESS
  ),
  loadCompaniesError: HOAC<Error>(actionTypes.LOAD_COMPANIES_ERROR)
}
