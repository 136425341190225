import { IAction } from 'types'
import { IPageAble } from 'types/pageable'
import { actionTypes } from './actions'

interface ICompaniesPage {
  loading: boolean,
  loaded: boolean,
  pageable: IPageAble | null,
  error: Error | null
}

const initialState: ICompaniesPage = {
  loading: false,
  loaded: false,
  pageable: null,
  error: null
}

function companiesPage(
  state = initialState,
  action: IAction<any>
): ICompaniesPage {
  const { type, payload } = action

  switch (type) {
    case actionTypes.LOAD_COMPANIES: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null
      }
    }

    case actionTypes.LOAD_COMPANIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        pageable: payload as IPageAble,
        loaded: true,
        error: null
      }
    }

    case actionTypes.LOAD_COMPANIES_ERROR: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: (payload as Error)
      }
    }

    default:
      return state
  }
}

export default companiesPage
