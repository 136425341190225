import { ChangeEventHandler } from 'react'
import { IconButton, InputAdornment } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import './EditableTextField.sass'
import { TextField } from 'vg-components'

interface IProps {
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  disabled: boolean
  toggleDisabled: () => void
  isToggleDisabled: boolean
}

const EditableTextField: React.FC<IProps> = ({
  value,
  onChange,
  disabled,
  toggleDisabled,
  isToggleDisabled
}) => (
  <TextField
    value={value}
    onChange={onChange}
    disabled={disabled}
    InputProps={{
      classes: { root: 'editable-text-field-input' },
      endAdornment: (
        <InputAdornment position="end">
          <IconButton onClick={toggleDisabled} disabled={isToggleDisabled}>
            {disabled ? <EditIcon /> : <CheckIcon color="primary" />}
          </IconButton>
        </InputAdornment>
      )
    }}
  />
)

export default EditableTextField
