import React from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Button } from 'vg-components'
import { ExitToApp } from '@material-ui/icons'

type ResourcesProps = {
    children: React.ReactNode
}

export default function SecuredRoute({ children }: ResourcesProps) {

  const { keycloak, initialized } = useKeycloak()

  if (!keycloak.authenticated) {
    return (<h1>User is not authenticated!</h1>)
  } else {
    return ( <> { children } </>)
  }

}
