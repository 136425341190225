/* eslint-disable no-unused-vars */
import { Fragment, useState } from 'react'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { Button } from 'vg-components'
import { ICompany } from 'types'
import { ICompanyStateItem } from 'store/companies/reducer'
import Company from 'components/Company/Company'
import './CompaniesList.sass'

interface IProps {
  companies: ICompanyStateItem[],
  onEdit: (id: string, company: Partial<ICompany>) => void
}

export const CompaniesList: React.FC<IProps> = ({
  companies, onEdit
}) => {
  const [expandedCompanies, setExpandedCompanies] = useState<{
    [key: string]: boolean;
  }>({})

  const toggleExpandCompany = (id: string) => {
    setExpandedCompanies({
      ...expandedCompanies,
      [id]: !expandedCompanies[id]
    })
  }

  return (
    <ul className="company-list">
      {companies.map(({ entity, isBeingEdited }) => (
        <Fragment key={entity!.id}>
          <li className="company-row">
            <span className="company-row-number">
              {entity!.companyNumber}
            </span>
            <span className="company-row-name">
              {entity!.companyName}
            </span>
            <span className="company-row-email">{entity!.email}</span>
            <span className="company-row-id">
              {entity!.sfInstallerCompanyId}
            </span>
            <Button
              onClick={() => toggleExpandCompany(entity!.id)}
              className="company-row-expand"
            >
              {expandedCompanies[entity!.id] ? (
                <ExpandLess style={{ fontSize: 35 }} />
              ) : (
                <ExpandMore style={{ fontSize: 35 }} />
              )}
            </Button>
          </li>
          {expandedCompanies[entity!.id] ? (
            <div className="company-row-expanded-item">
              <Company
                className="company-item"
                company={entity!}
                onEdit={onEdit}
                isBeingEdited={isBeingEdited}
              />
            </div>
          ) : null}
        </Fragment>
      ))}
    </ul>
  )
}
