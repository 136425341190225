import { Button, Typography } from 'vg-components'
import { BrowserRouter as Router } from 'react-router-dom'

import Companies from 'containers/companies/Companies'
import './App.sass'
import SecuredRoute from '../../components/Security/SecuredRoute'
import { ExitToApp } from '@material-ui/icons'
import React from 'react'
import { useKeycloak } from '@react-keycloak/web'

function App() {
  const { keycloak } = useKeycloak()

  return (
    <Router>
      <SecuredRoute>
        <header className="header">
          <Typography variant="h1">HDL partner management</Typography>
          <Button
            variant='outlined'
            onClick={() => keycloak.logout()}
            startIcon={<ExitToApp />}
          >
              Logout
          </Button>
        </header>
        <div className="body">
          <Companies />
        </div>
      </SecuredRoute>
    </Router>
  )
}

export default App
