import { IAction, ICompany, PartialBy } from 'types'
import { actionTypes } from './actions'

export interface ICompanyStateItem {
  id: string;
  loading: boolean;
  loaded: boolean;
  isBeingEdited: boolean;
  entity: ICompany | null;
  error: Error | null;
}

const initialState: ICompanyStateItem[] = []

function setStateItem(
  state: ICompanyStateItem[],
  newStateItem: PartialBy<ICompanyStateItem, 'entity'>
): ICompanyStateItem[] {
  const existing = state.find(i => i.id === newStateItem.id)
  if (existing)
    return state.map(item => {
      if (item.id !== newStateItem.id) return item

      return {
        ...item,
        ...newStateItem
      }
    })

  return [
    ...state,
    {
      ...newStateItem,
      entity: newStateItem?.entity || null
    }
  ]
}

function setStateItems(
  newItems: PartialBy<ICompanyStateItem, 'entity'>[]
) {
  return [
    ...newItems.map(newStateItem => ({
      ...newStateItem,
      entity: newStateItem?.entity || null
    }))
  ]
}

function companies(
  state = initialState,
  action: IAction<ICompany | string | Error | [string, Error] | ICompany[]>
): ICompanyStateItem[] {
  const { type, payload } = action

  switch (type) {
    case actionTypes.LOAD_COMPANY: {
      return setStateItem(state, {
        id: payload as string,
        loading: true,
        loaded: false,
        isBeingEdited: false,
        error: null
      })
    }

    case actionTypes.LOAD_COMPANY_SUCCESS: {
      return setStateItem(state, {
        id: (payload as ICompany).id,
        loading: false,
        loaded: true,
        isBeingEdited: false,
        entity: payload as ICompany,
        error: null
      })
    }

    case actionTypes.LOAD_COMPANY_ERROR: {
      return setStateItem(state, {
        id: payload as string,
        loading: true,
        loaded: false,
        isBeingEdited: false,
        error: payload as Error
      })
    }

    case actionTypes.LOAD_COMPANIES_SUCCESS: {
      return setStateItems((payload as ICompany[])
        .map(c => ({
          id: c.id,
          loaded: true,
          loading: false,
          isBeingEdited: false,
          entity: c,
          error: null
        }))
      )
    }

    case actionTypes.EDIT_COMPANY: {
      return setStateItem(state, {
        id: payload as string,
        loading: false,
        loaded: true,
        isBeingEdited: true,
        error: null
      })
    }

    case actionTypes.EDIT_COMPANY_SUCCESS: {
      return setStateItem(state, {
        id: (payload as ICompany).id,
        loading: false,
        loaded: true,
        entity: payload as ICompany,
        isBeingEdited: false,
        error: null
      })
    }

    case actionTypes.EDIT_COMPANY_ERROR: {
      return setStateItem(state, {
        id: (payload as [string, Error])[0],
        loading: false,
        loaded: true,
        isBeingEdited: false,
        error: payload as [string, Error][1]
      })
    }

    default:
      return state
  }
}

export default companies
