/* eslint-disable no-unused-vars */
import { createStore, combineReducers } from 'redux'
import { createSelectorHook } from 'react-redux'
import { IAction } from 'types'
import search from './search/reducer'
import companies from './companies/reducer'
import companiesPage from 'containers/companies/store/reducer'

const rootReducer = combineReducers({
  search,
  companies,
  companiesPage
})

const store = createStore(rootReducer)
// @ts-ignore
window.s = store
export type RootState = ReturnType<typeof store.getState>;
export const useSelector = createSelectorHook<RootState>()
export const dispatch = store.dispatch as (action: IAction<unknown>) => void
export default store
