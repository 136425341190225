import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useQuery } from 'utils/useQuery'
import { CircularProgress } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { CompaniesList } from 'components/CompaniesList/CompaniesList'
import Search from 'components/Search/Search'
import { editCompany, loadCompanies } from 'store/companies/async'
import { useSelector } from 'store'
import './Companies.sass'

function Companies() {
  const query = useQuery()
  const queryPageNumber = Number(query.get('pageNumber'))
  const [params, setParams] = useState<{
    pageNumber: number, companyName: string
  }>({
    pageNumber: queryPageNumber > 0 ? queryPageNumber : 1,
    companyName: query.get('companyName') || ''
  })

  const history = useHistory()
  useEffect(() => {
    const { pageNumber, companyName } = params
    history.push(`/?pageNumber=${pageNumber}${companyName ? `&companyName=${companyName}` : ''}`)
    loadCompanies({ pageNumber, companyName: companyName.trim() })
  }, [params])

  const companies = useSelector(store => store.companies).filter(
    i =>
      i.loaded &&
      !i.error
  ).sort((a: any, b: any) => a.entity!.companyName > b.entity!.companyName ? 1 : -1)

  const { loading, pageable } = useSelector(store => store.companiesPage)

  return (
    <>
      <Search
        defaultValue={params.companyName}
        onSearch={(companyName: string) => {
          setParams({ pageNumber: 1, companyName })
        }} />
      <div className="companies-box">
        {Boolean(companies.length) && (
          <>
            <div className="companies-header">
              <div className="companies-header-number">#</div>
              <div className="companies-header-name">Name</div>
              <div className="companies-header-email">Email</div>
              <div className="companies-header-id">Salesforce Account ID</div>
              <div style={{ width: 35 }}></div>
            </div>
            <div className="companies-list-box">
              <CompaniesList companies={companies} onEdit={editCompany} />
              {loading && (
                <div className="companies-loader-overlay" />
              )}
            </div>
          </>
        )}
        {loading && !companies.length && (
          <CircularProgress className="companies-loader" />
        )}
        {Boolean(pageable) &&
          <div className="pagination-box">
            <Pagination
              color="primary"
              count={pageable!.totalPages}
              shape="rounded"
              page={params.pageNumber}
              onChange={(e, pageNumber) => setParams({ ...params, pageNumber })}
            />
          </div>
        }
      </div>
    </>
  )
}

export default Companies
