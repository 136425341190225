interface ILanguageOption {
  active: boolean
  defaultValue: boolean
  label: string
  validFor: null
  value: string
}

export const landOptions: string[] = [
  'DE', 'BE', 'PL', 'FR', 'SI', 'NL', 'HR', 'AT', 'IT'
]

export const statusOptions: {label: string; value: string}[] = [
  { label: 'Aktiviert', value: 'ACTIVE' }, { label: 'Deaktiviert', value: 'INACTIVE' }
]

export const languageOptions: ILanguageOption[] = [
  {
    active: true,
    defaultValue: false,
    label: 'ar',
    validFor: null,
    value: 'ar'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Czech',
    validFor: null,
    value: 'cs'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Danisch',
    validFor: null,
    value: 'da'
  },
  {
    active: true,
    defaultValue: false,
    label: 'German',
    validFor: null,
    value: 'de'
  },
  {
    active: true,
    defaultValue: false,
    label: 'English',
    validFor: null,
    value: 'en'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Spanish',
    validFor: null,
    value: 'es'
  },
  {
    active: true,
    defaultValue: false,
    label: 'es_CATALAN',
    validFor: null,
    value: 'es_CATALAN'
  },
  {
    active: true,
    defaultValue: false,
    label: 'es_GALLEGO',
    validFor: null,
    value: 'es_GALLEGO'
  },
  {
    active: true,
    defaultValue: false,
    label: 'es_VASCO',
    validFor: null,
    value: 'es_VASCO'
  },
  {
    active: true,
    defaultValue: false,
    label: 'fa',
    validFor: null,
    value: 'fa'
  },
  {
    active: true,
    defaultValue: false,
    label: 'French',
    validFor: null,
    value: 'fr'
  },
  {
    active: true,
    defaultValue: false,
    label: 'fr_nl',
    validFor: null,
    value: 'fr_nl'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Croatian',
    validFor: null,
    value: 'hr_HR'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Hungarian',
    validFor: null,
    value: 'hu'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Italian',
    validFor: null,
    value: 'it'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Dutch',
    validFor: null,
    value: 'nl'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Dutch (Belgium)',
    validFor: null,
    value: 'nl_be'
  },
  {
    active: true,
    defaultValue: false,
    label: 'no',
    validFor: null,
    value: 'no'
  },
  {
    active: true,
    defaultValue: false,
    label: 'pt',
    validFor: null,
    value: 'pt'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Romanian',
    validFor: null,
    value: 'ro'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Russian',
    validFor: null,
    value: 'ru'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Slovakian',
    validFor: null,
    value: 'sk_SK'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Slovanian',
    validFor: null,
    value: 'sl_SI'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Turkish',
    validFor: null,
    value: 'tr'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Ukrainian',
    validFor: null,
    value: 'uk'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Chinese',
    validFor: null,
    value: 'zh'
  },
  {
    active: true,
    defaultValue: false,
    label: 'Polish',
    validFor: null,
    value: 'pl'
  },
  {
    active: true,
    defaultValue: false,
    label: 'sv',
    validFor: null,
    value: 'sv'
  },
  {
    active: true,
    defaultValue: false,
    label: 'fi',
    validFor: null,
    value: 'fi'
  },
]
