import { actionTypes } from './actions'
import { IAction } from 'types'

interface IState {
  value: string
}

const initialState: IState = {
  value: ''
}

function search(state = initialState, action: IAction<string>): IState {
  const { type, payload } = action
  switch (type) {
    case actionTypes.SET_VALUE: {
      return {
        ...state,
        value: payload
      }
    }

    default: {
      return state
    }
  }
}

export default search
