import { API_URL } from 'config'
import { IPageAble } from 'types/pageable'
import { actions } from './actions'
import { dispatch } from 'store'
import { ICompany } from 'types/company'
import { actions as companiesPageActions } from 'containers/companies/store/actions'
import axiosApiInstance from '../../auth/axiosApiInstance'

const _ = undefined

export const editCompany = async (id: string, company: Partial<ICompany>) => {
  dispatch(actions.editCompany(id))

  try {
    const { data } = await axiosApiInstance.patch(
      `${API_URL}/installerCompanies/${id}`,
      company
    )

    dispatch(actions.editCompanySuccess(data))
  } catch (error) {
    dispatch(actions.editCompanyError(error as any))
  }
}

const pageSize = 20
export const loadCompanies = async (options: {
  pageNumber: number,
  companyName?: string
}
) => {
  try {
    const { pageNumber, companyName } = options
    dispatch(companiesPageActions.loadCompanies(_))
    const { data } = await axiosApiInstance.get<{
      content: ICompany[];
      pageable: IPageAble;
    }>(`${API_URL}/installerCompanies`, {
      params: {
        pageSize,
        pageNumber: pageNumber - 1,
        ...(companyName && { companyName })
      },
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      }
    })

    dispatch(companiesPageActions.loadCompaniesSuccess(data.pageable))
    dispatch(actions.loadCompaniesSuccess(data.content))
  } catch (error) {
    dispatch(companiesPageActions.loadCompaniesError(error as any))
  }
}
